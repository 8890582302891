.dashboard-select .ant-select-selector {
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.dashboard-select .ant-select-arrow {
    color: #fff !important;
}

.ant-result-title {
    color: "#595959" !important;
    font-size: 20px !important;
}

:root {
    --amplify-primary-color: #1890ff;
    --amplify-primary-tint: #1890ff;
    --amplify-primary-shade: #1890ff;
  }

.amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* height: 100vh; */
  }
  

.ant-layout-sider-children .logo {
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 16px;
}

.ant-layout-sider-children .logo .logo-img {
    width: 40px;
    height: 40px;
}

.ant-layout-sider-children .logo .logo-text {
    display: inline-block;
    padding-left: 10px;
    font-size: 20px;
    color: #fff;
}

.slider {
    overflow: auto;
    min-height: 100vh;
    left: 0;
}

.slider .logout {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 200px;
    padding: 12px 14px;
    transition: left 0.2s;
}

.slider .customer {
    position: fixed;
    left: 0;
    bottom: 50px;
    width: 200px;
    padding: 12px 14px;
    transition: left 0.2s;
}

.content {
    transition: margin 0.2s;
}

.content .default-header {
    background: #fff;
    padding: 0 0 0 0px;
    color: #909090;
}

.trigger {
    font-size: 18px;
    line-height: 0px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
}

@media (min-width: 768px) {
    .trigger {
        line-height: 64px;
    }
}    


.trigger:hover {
    color: #909090;
}

.period-selector {
    font-size: 15px;
    line-height: 64px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
}

.energy-option {
    font-size: 18px;
    line-height: 64px;
    padding: 0 14px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
}

.dollar-option {
    font-size: 18px;
    line-height: 64px;
    padding: 0 14px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
}

.energy-option:hover, .dollar-option:hover {
    color: #909090;
}

.content .header .title {
    font-size: 20px;
}

.content .body {
    overflow: auto;
    margin: 0;
}

.disabled-link {
    cursor: not-allowed;
}

.disabled-link a {
    pointer-events: none;
    color: #fff !important;
    opacity: 0.65;
}

.switch-header .ant-switch {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.switch-header .ant-switch .ant-switch-inner {
    margin: 2px 0px 0 24px;
}

.switch-header .ant-switch-checked .ant-switch-inner {
    margin: 2px 20px 0px 0px;
}

.switch-header .ant-switch .ant-switch-handle::before {
    border-radius: 14px !important;
}

.switch-header .ant-switch .ant-switch-handle {
    width: 20px !important;
    height: 20px !important;
}

.switch-header .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px - 2px) !important;
}

@media (min-width: 768px) {
    .switch-header .ant-switch .ant-switch-handle::before {
        border-radius: 14px !important;
    }

    .switch-header .ant-switch .ant-switch-handle {
        width: 28px !important;
        height: 28px !important;
    }

    .switch-header .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 28px - 2px) !important;
    }

    .switch-header .ant-switch .ant-switch-inner {
        margin: 3px 0px 0 28px;
    }

    .switch-header .ant-switch-checked .ant-switch-inner {
        margin: 3px 28px 0 0px;
    }
}

.accounts-list-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.accounts-list-container .list {
    width: 320px;
    padding: 0px 0px;
}

.accounts-list-container .logo {
    max-width: 150px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.accounts-list-container .logo-image {
    width: 100%;
    height: auto;
}

.accounts-list-container .empty-div {
    display: hidden;
    width: 100px;
    height: 20%;
}

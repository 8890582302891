@import '~antd/dist/antd.css';

body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F3F7;
  color: #909090;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.signup-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.signup-screen .logo {
  max-width: 150px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.signup-screen .logo-image {
  width: 100%;
  /* height: auto; */
}
.analytics-dashboard-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.analytics-dashboard {
  display: flex;
  justify-content: center;
}

.gapi-data-chart-container {
  margin: 20px;
}

.gapi-view-selector-container {
  display: none;
}

.analytics-sign-out-button {
  display: none;
}

/*.gapi-data-chart-container .gapi-analytics-data-chart div div {
  min-width: 250px;
  width: 45% !important;
  max-width: 600px;
  min-height: 200px;
  height: 45% !important;
  max-height: 450px;
}*/